<script setup lang="ts">
import type { Placement } from '@floating-ui/dom'
import type { DropDownItem } from '@rialtic/types'
import { computed, ref, useElementSize, watch } from '#imports'
import Menu from './Menu.vue'

const activeItem = defineModel<DropDownItem>()

interface Props {
  items: DropDownItem[]
  placeholder?: string
  placement?: Placement
  buttonBorderRadius?: string
  minWidth?: number
  fitContent?: boolean
  customDropDownClasses?: string
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  placeholder: 'Select',
  placement: 'bottom-start',
  buttonBorderRadius: 'rounded-lg',
})

const emit = defineEmits<{
  select: [item: DropDownItem]
}>()

const button = ref(null)
const isOpen = ref(false)

const { width: buttonWidth } = useElementSize(button)

const selectItem = (item: DropDownItem) => {
  activeItem.value = item
  emit('select', item)
  isOpen.value = false
}

watch(
  () => props.items,
  (newVal, oldVal) => {
    const newSelectedItem = props.items.find(
      (item) => item.label === activeItem.value?.label,
    )

    if (!newSelectedItem) return

    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      activeItem.value = newSelectedItem
    }
  },
)

const maxWidth = computed(() => {
  return Math.max(buttonWidth.value ?? 0, props.minWidth ?? 0, 180)
})
</script>

<template>
  <Menu
    v-model:open="isOpen"
    class="relative flex"
    :menu-styles="{ maxWidth, minWidth: fitContent ? 'min-content' : false }"
    :placement="placement"
    menu-classes="w-full"
  >
    <template #activator="{ toggle }">
      <!-- figma note: using py-1 since text in figma  -->
      <button ref="button" class="w-full" type="button" @click="toggle(true)">
        <slot v-bind="{ buttonBorderRadius, modelValue, placeholder }">
          <div
            class="bg-surface flex w-full flex-1 items-center justify-between space-x-2 truncate border border-neutral-200 px-3 py-2 text-neutral-800"
            :class="buttonBorderRadius ? buttonBorderRadius : 'rounded-lg'"
          >
            <div class="subtitle-2 truncate">
              {{
                modelValue && modelValue.label != ''
                  ? modelValue.label
                  : placeholder
              }}
            </div>
            <div class="i-ph-caret-down-bold h-4 w-4" />
          </div>
        </slot>
      </button>
    </template>

    <ul class="w-full flex-col space-y-1 p-1 max-h-[calc(100vh-150px)] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
      <li v-for="item in items" :key="item.label">
        <slot
          v-bind="{
            isVisible: isOpen,
            item,
            modelValue: activeItem,
            selectItem,
          }"
          name="option"
        >
          <button
            class="hover:bg-surface-bg flex h-9 w-full items-center rounded-md p-2 hover:bg-neutral-100"
            type="button"
            :data-testid="`dropDown_${item.label
              .replace(/\s/g, '-')
              .toLowerCase()}`"
            @click="selectItem(item)"
          >
            <div
              class="i-ph-check mr-2 h-5 w-5"
              :class="
                activeItem?.itemValue === item.itemValue
                  ? 'opacity-100'
                  : 'opacity-0'
              "
            />
            <div class="body-2 max-w-9/10 truncate text-neutral-900">
              {{ item.label }}
            </div>
          </button>
        </slot>
      </li>
    </ul>
  </Menu>
</template>
